<template>
  <div class="view pa24">
    状态：
    <el-radio v-model="radioValue" v-for="(item, index) in radioArr" :key="index" class="mr10" style="margin: 0"
      :label="item.value" border size="medium">{{ item.lable }}</el-radio>
    <el-button type="primary" @click='selectGoodsAssemblePage'>搜索</el-button>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
      :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column prop="goodsName" align="center" label="拼团商品" show-overflow-tooltip />
        <el-table-column prop="nickeName" align="center" label="团长" show-overflow-tooltip />
        <el-table-column prop="assembleNum" align="center" label="成团人数" width="80" />
        <el-table-column align="center" label="还差人数" width="80">
          <template slot-scope="scope">
            {{ scope.row.assembleNum - scope.row.putAssemble }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="拼团时间" width="200">
          <template slot-scope="scope">
            <div>起 ：{{ setGetDataTimeSec(scope.row.createTime) }}</div>
            <div>止 ：{{ setGetDataTimeSec(scope.row.endTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="拼团状态" width="100">
          <template slot-scope="scope">
            {{ scope.row.state | isState }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="text"
              @click="$router.push(`/assembleOrdersInfo?id=${scope.row.assembleId}`)">详情</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { selectGoodsAssemblePage } from "@/api/joinGroup";
import { getDataTimeSec } from "@/utils";
export default {
  name: "listParticipants",
  data() {
    return {
      radioValue: "",
      radioArr: [
        { value: "", lable: "全部" },
        { value: "1", lable: "拼团中" },
        { value: "2", lable: "拼团成功" },
        { value: "3", lable: "拼团失败（待退款）" },
        { value: "4", lable: "拼团失败（已退款）" },
      ],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  components: {
    commonTable,
  },
  computed: {
    setGetDataTimeSec() {
      return (val) => getDataTimeSec(val);
    },
  },
  filters: {
    isState(val) {
      const type = { 0: "-", 1: "拼团中", 2: "拼团成功", 3: "拼团失败", 4: "已退款" };
      return type[val];
    },
  },
  created() {
    this.selectGoodsAssemblePage();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
    },
    async selectGoodsAssemblePage() {
      let data = {
        state: this.radioValue,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      };
      try {
        this.loading = true;
        const result = await selectGoodsAssemblePage(data);
        this.loading = false;
        this.tableData = result?.data;
        this.total = result?.data?.length;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>